<template>
  <div class="bict-bottom">
    <div >
      <div class="bict-container">
        <div class="bict-copy">
          <p class="text">copyright © TEWE</p>
        </div>
        <div class="bict-copy">
          <p class="text">{{ $t('lianxiwomen') }}  mailto:TEWEexchange@gmail.com</p>
        </div>
        <!--      <div class="bict-about">-->
        <!--        <a class="text" href="https://www.bitcglobaltrade.com/about">{{ $t('About us') }}</a>-->
        <!--      </div>-->
        <!--      <div class="bict-about" @click="$router.push('/about')">-->
        <!--        <span class="text">{{ $t('About us') }}</span>-->
        <!--      </div>-->

      </div>
      <div class="bict-container">
        <!--      <div class="bict-register">-->
        <!--        <a class="text" href="https://api.bitcglobaltrade.com/procoin/article/#/passgeDetail?article_id=48">{{ $t('Registration Agreement') }}</a>-->
        <!--      </div>-->

        <div class="bict-user">
          <a class="text" href="https://api.wkygg.cn//article/#/passgeDetail?article_id=216">{{ $t('User Agreement and Privacy Policy') }}</a>
        </div>
        <div class="bict-copy">
          <p class="text">{{ $t('dianhua') }}  +65 86504648</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BictBottom',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@media screen and (min-width: 1200px) {
  .bict-container{
    width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .bict-container {

  }
}

.bict-bottom {
  background: #071426;
  width: 100%;
  margin-top: 20px;

  .bict-container {
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 3px;

    .bict-copy {
      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #566679;
      }
    }

    .bict-about {
      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #566679;
      }
    }

    .bict-user {
      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #566679;
      }
    }

    .bict-register {
      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #566679;
      }
    }
  }
}

</style>
