<template>
  <div class="bict-header">
    <div class="bict-container">
      <div class="bict-header-left">
        <div class="bict-logo">
          <img src="../assets/icon/logo.png" alt="图标" class="logo">
        </div>

        <el-menu :default-active="activeMenu" class="hidden-sm-and-up bict-nav-dropdown" mode="horizontal" @select="onMenuSelect">
          <el-submenu index="2">
            <template slot="title"><i class="el-submenu__title el-icon-menu"></i></template>
            <el-menu-item index="/" route="/">{{ $t('Home') }}</el-menu-item>
            <el-menu-item index="/about" route="/about">{{ $t('About us') }}</el-menu-item>
            <el-menu-item index="/notice" route="/notice">{{ $t('Announcement') }}</el-menu-item>
<!--            <el-menu-item index="/college" route="/college">{{ $t('CoinPlus Global Finance Institute') }}</el-menu-item>-->
            <el-menu-item index="/download" route="/download">{{ $t('Download') }}</el-menu-item>
          </el-submenu>
        </el-menu>

        <div class="bict-nav hidden-sm-and-down">
          <ul class="bict-ul">
            <li class="bict-li">
              <img src="../assets/icon/icon_menu.png" alt="" class="icon">
            </li>
            <li class="bict-li" @click="$router.push('/')">
              <span class="text">{{ $t('Home') }}</span>
            </li>
            <li class="bict-li" @click="$router.push('/about')">
              <span class="text">{{ $t('About us') }}</span>
            </li>
            <li class="bict-li" @click="handleNotice">
              <span class="text">{{ $t('Announcement') }}</span>
            </li>
<!--            <li class="bict-li" @click="$router.push('/college')">-->
<!--              <span class="text">{{ $t('CoinPlus Global Finance Institute') }}</span>-->
<!--            </li>-->
            <li class="bict-li" @click="$router.push('/download')">
              <span class="text">{{ $t('Download') }}</span>
            </li>
          </ul>
        </div>
      </div>
<!--      <div class="bict-header-right">-->
<!--        <div class="bict-lang">-->
<!--          <span class="text" @click="switchLang" v-if="lang === 'en'">中文</span>-->
<!--          <span class="text" @click="switchLang" v-else>English</span>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {getLanguage, setup} from "@/lang";

export default {
  name: 'BictHeader',
  props: {},
  data () {
    return {
      lang: getLanguage()
    };
  },
  computed: {
    activeMenu() {
      return this.$route.path;
    }
  },
  methods: {
    onMenuSelect (index) {
      this.$router.push(index);
    },
    switchLang () {
      const lang = this.lang === 'en' ? 'zh' : 'en';
      setup(lang);
      this.lang = getLanguage();
    },
    handleNotice () {
      this.$router.push({
        path: '/notice/content',
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.el-menu {
  background: #fff !important;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
  background: transparent !important;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
  color: #000 !important;
  font-weight: bold;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: white !important;
}
@media screen and (min-width: 1200px) {
  .bict-container{
    width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .bict-nav-dropdown {
    margin-left: 20px !important;
  }
}

.bict-header {
  position: fixed;
  z-index: 1999;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  height: 60px;

  .bict-container {
    margin: auto;
    height: 100%;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 20px;

    .bict-header-left {
      height: 100%;
      flex: 1;
      display: flex;

      .bict-logo {
        display: flex;
        align-items: center;
        height: 100%;

        .logo {
          width: 83px;
          height: 43px;
        }
      }

      .bict-nav-dropdown {
        height: 100%;
        margin-left: 67px;
        display: block;
        background: transparent;

        .el-submenu__title {
          color: black;
          font-weight: bold;
        }

        .el-submenu__title,
        .el-submenu__title:hover {
          background-color: transparent !important;
        }

        .el-dropdown-link {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .bict-nav {
        flex: 1;
        height: 100%;
        margin-left: 67px;

        .bict-ul {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;

          .bict-li {
            display: flex;
            margin-left: 67px;
            height: 100%;
            align-items: center;
            float: left;
            cursor: pointer;

            &:first-child {
              margin-left: 0;
            }

            .icon {
              width: 16px;
              height: 16px;
            }

            .text {
              font-size: 14px;
              font-family: FZHei-B01T;
              font-weight: 400;
              color: #000;
            }
          }
        }
      }
    }

    .bict-header-right {
      overflow: hidden;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .bict-login {
        display: flex;
        align-items: center;

        .bict-btn-login {
          background: #fff;
          color: #000;
        }
      }

      .bict-register {
        display: flex;
        margin-left: 10px;
        align-items: center;
      }

      .bict-hr {
        margin-left: 25px;
        margin-right: 25px;
        position: relative;

        &::before {
          position: absolute;
          top: 16px;
          bottom: 16px;
          width: 1px;
          background: #000;
          content: '';
        }
      }

      .bict-lang {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        .text {
          font-size: 14px;
          font-family: FZHei-B01T;
          font-weight: 400;
          color: #000;
        }
      }
    }
  }
}
</style>
