<template>
  <div class="download">
    <bict-header/>
    <div class="bict-platform">
      <div class="bict-platform-title">
        <h3 class="bict-h3">{{ $t('Anytime, anywhere Real-time view Multi-platform terminal trading') }}</h3>
        <h3 class="bict-sub">{{ $t('Download Android or iOS mobile, PC client and trade/recharge anytime, anywhere') }}</h3>
      </div>
      <div class="bict-platform-box">
        <div class="bict-platform-left">
          <div class="bict-platform-note">
<!--            <div class="bict-platform-nav">-->
<!--              <img src="../assets/icon/icon_platform_bind.png" alt="" class="icon">-->
<!--              <p class="text">{{ $t('Binding gold medal') }}</p>-->
<!--              <p class="note">{{$t('Brokerage one click automatic documentary No need to stare at the plate')}}</p>-->
<!--            </div>-->
            <div class="bict-platform-nav">
              <img src="../assets/icon/icon_platform_quick.png" alt="" class="icon">
              <p class="text">{{ $t('Convenient and fast') }}</p>
              <p class="note">{{$t('Trading anytime, anywhere, safe and secure')}}</p>
            </div>
            <div class="bict-platform-nav">
              <img src="../assets/icon/icon_platform_any.png" alt="" class="icon">
              <p class="text">{{ $t('Trade at any time') }}</p>
              <p class="note">{{$t('The market trend switches freely and is clear at a glance')}}</p>
            </div>
          </div>
          <div class="bict-platform-buttons">
            <div class="bict-platform-button">
              <button class="bict-btn bict-btn-download" @click="handleDownload('ios')">
                <img src="../assets/icon/icon_download_ios.png" alt="" class="icon">
                <span class="text">DOWNLOAD IOS</span>
              </button>
            </div>
            <div class="bict-platform-button">
              <button class="bict-btn bict-btn-download" @click="handleDownload('apk')">
                <img src="../assets/icon/icon_download_android.png" alt="" class="icon">
                <span class="text">DOWNLOAD APK</span>
              </button>
            </div>
<!--            <div class="bict-platform-button">-->
<!--              <button class="bict-btn bict-btn-download" @click="handleDownload('pc')">-->
<!--                <img src="../assets/icon/icon_download_pc.png" alt="" class="icon">-->
<!--                <span class="text">DOWNLOAD PC</span>-->
<!--              </button>-->
<!--            </div>-->
          </div>
        </div>
        <div class="bict-platform-right">
          <img src="../assets/icon/icon_phone.png" alt="" class="image">
        </div>
      </div>
    </div>

    <bict-bottom/>
  </div>
</template>

<script>
import BictHeader from "../components/BictHeader";
import BictBottom from "../components/BictBottom";

export default {
  name: "index",
  components: {
    BictHeader,
    BictBottom
  },
  data () {
    return {}
  },
  methods: {
    handleDownload (type) {
      let url;
      if (type === "ios") {
        url = "http://fen.asliyueoscc.icu/zti";
      } else if (type === "apk") {
        url = "http://fen.asliyueoscc.icu/zti";
      } else {
        url = "http://fen.asliyueoscc.icu/zti";
      }
      window.open(url, "_blank")
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .download {
    .bict-platform {
      width: 1000px;
    }
  }
}

@media screen and (max-width: 768px) {
  .download {
    .bict-platform {

      .bict-platform-title {
        .bict-h3 {
          font-size: 16px !important;
        }

        .bict-sub {
          font-size: 12px !important;
        }
      }
      .bict-platform-box {
        margin-top: 40px !important;
        flex-direction: column;

        .bict-platform-left {
          .bict-platform-note {
            .bict-platform-nav {
              .text {
                font-size: 14px !important;
              }
              .note {
                font-size: 14px !important;
              }
            }
          }
        }

        .bict-platform-right {
          .image {
            width: 100% !important;
            height: auto !important;
          }
        }

      }
    }
  }
}

.download {
  background: #fff;

  .bict-platform {
    margin: 0 auto;
    overflow: hidden;
    padding: 100px 18px 200px;

    .bict-platform-title {
      text-align: center;

      .bict-h3 {
        font-size: 25px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000;
      }

      .bict-sub {
        margin-top: 8px;
        font-size: 14px;
        font-family: FZHei-B01T;
        font-weight: 400;
        color: #7D848D;
      }
    }

    .bict-platform-box {
      display: flex;
      margin-top: 100px;
      justify-content: space-between;

      .bict-platform-left {
        margin-right: 20px;

        .bict-platform-note {
          .bict-platform-nav {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;

            .icon {
              width: 23px;
              height: 23px;
            }

            .text {
              margin-left: 13px;
              font-size: 18px;
              font-family: FZHei-B01T;
              font-weight: bold;
              color: #000;
            }

            .note {
              margin-left: 25px;
              font-size: 18px;
              font-family: FZHei-B01T;
              color: #000;
              flex: 1;
              flex-wrap: wrap;
              word-break: break-all;
            }
          }
        }

        .bict-platform-buttons {
          margin-top: 40px;
          width: 100%;

          .bict-platform-button {
            text-align: center;
            margin-bottom: 11px;

            .bict-btn-download {
              width: 240px;
              height: 55px;
              background: linear-gradient(90deg, #112541, #1C3455);
              border-radius: 28px;
              padding-left: 40px;
              box-sizing: border-box;

              .icon {
                float: left;
              }

              .text {
                float: left;
                font-size: 16px;
                line-height: 24px;
                display: inline-block;
                font-family: FZHei-B01T;
                font-weight: 400;
                margin-left: 14px;
                color: #fff;
              }
            }
          }
        }
      }

      .bict-platform-right {
        .image {
          width: 555px;
          height: 384px;
        }
      }
    }
  }
}
</style>
